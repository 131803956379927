<template>
  <div class="page articles">
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="panel unique-scrollbar">
            <k-articles-table v-model="selectedArticles"></k-articles-table>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <k-button
      v-if="admin"
      variant=""
      @click="Kimutatas"
      size="sm"
      icon="fa-2x fa-chart-line"
      class="add-user mr-50"
    ></k-button>
    <k-button
      variant="primary"
      @click="UjCikk"
      size="sm"
      icon="fa-plus"
      class="add-user"
    ></k-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { hirService } from '../services/HirService';
import { AppStoreTypes } from '../store/modules/app';
import { Jogosultsag } from '../enums/Cimkek/jogosultsag';

export default {
  name: 'articles-page',
  data() {
    return {
      selectedArticles: [],
    };
  },
  mounted() {},
  created() {},
  methods: {
    UjCikk() {
      this.$router.push({ name: 'Article' });
    },
    Kimutatas() {
      hirService.GetHirekOlvasottsaga().then(data => {
        let link = document.createElement('a');
        document.body.appendChild(link);
        link.setAttribute('type', 'hidden');
        link.href =
          'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
          data;
        link.download = 'cikkek-olvasottsaga.xlsx';
        link.click();
        document.body.removeChild(link);
      });
    },
  },
  computed: {
    ...mapGetters({
      user: AppStoreTypes.user.get,
    }),
    admin: function() {
      return (
        this.user?.Jogosultsag?.id == Jogosultsag.Szerkeszto ||
        this.user?.ProjektAdmin ||
        this.user?.IsGod
      );
    },
  },
  watch: {},
};
</script>

<style scoped>
.mr-50 {
  right: 70px !important;
}
</style>
